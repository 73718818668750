import type { Organization } from "@/types/organizations";

export const useOrgStore = defineStore("org", () => {
	const { $sentry } = useNuxtApp();
	const { viewOrganization, setOrgType, listOrganizationSubsidiaries, addSeedData } = useOrgM();
	const currentOrg = ref<Organization | null>(null);
	const orgList = ref<Organization[]>([]);

	function updateCurrentOrg(org: Organization) {
		currentOrg.value = org;
	}

	async function updateOrgs(id: string) {
		try {
			const org = await viewOrganization(id);
			currentOrg.value = {
				...org,
				// test seed data
				type: setOrgType(org),
			};
			const holdingOrgId = !org.subsidiary_of ? org.id : org.subsidiary_of;

			// get all child orgs
			const subOrgs = await listOrganizationSubsidiaries(holdingOrgId);
			if (holdingOrgId === org.id) {
				orgList.value = [currentOrg.value, ...subOrgs].map(org => addSeedData(org));
			} else {
				const holdingOrg = await viewOrganization(holdingOrgId);
				orgList.value = [holdingOrg, ...subOrgs].map(org => addSeedData(org));
			}
		} catch (err: any) {
			$sentry?.captureException(`Can not update organization info, org id: ${id} ${err.toString()}`);
		}
	}

	return {
		currentOrg,
		orgList,
		updateOrgs,
		updateCurrentOrg,
	};
});
