export const getUserInitial = (name: string) => {
	return name
		.split(" ")
		.map((v: string) => v.charAt(0))
		.join("");
};

export const randomString = (length = 32) => {
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};
