const unauthenticatedRoutes: RegExp[] = [
	/^\/sign-in$/,
	/^\/change-password$/,
	/^\/forgot-password$/,
	/^\/kiosk\/(?!$).+$/, // Matches all subroutes of /kiosk/* but not /kiosk itself,
	/^\/auth$/,
];

function isPathInRegexList(path: string): boolean {
	return unauthenticatedRoutes.some(regex => regex.test(path));
}

export default defineNuxtRouteMiddleware(async to => {
	const { refreshUser } = useURM();
	const userStore = useUserStore();

	// If navigate to unauthenticated route, do nothing
	if (!isPathInRegexList(to.path) && !userStore.user) {
		try {
			const user = await refreshUser();
			if (user) {
				userStore.setUser(user);
				return;
			}
		} catch (err) {
			// console.log(err);
		}
		return navigateTo("/sign-in?redirect=" + to.path);
	}
});
