import type { Organization, OrgType } from "@/types/organizations";

export const useOrgM = () => {
	const { viewOrganization: viewOrganizationCall, listSubsidiariesOrganization } = useOrgMApi();

	const viewOrganization = async (organization_id: string) => {
		const { response, error } = await viewOrganizationCall<Organization>(organization_id);

		if (error) throw error;
		return response!.data;
	};

	const listOrganizationSubsidiaries = async (organization_id: string) => {
		const { response, error } = await listSubsidiariesOrganization<Organization[]>(organization_id);
		if (error) {
			if (error.message === "No subsidiaries found for this organization. Please, try again.") return [];
			throw error;
		}
		return response!.data;
	};

	// test map function
	const addSeedData = (org: Organization): Organization => {
		return {
			...org,
			type: setOrgType(org),
		};
	};

	const setOrgType = (org: Organization): OrgType => {
		if (org.id === "59a83841-d85a-483d-9626-ebf0a880ba44") {
			return "holding";
		} else if (
			org.id === "fcd7cc6f-1de0-45aa-bd2c-8b106b64fc8b" ||
			org.id === "8f11c6e7-a75a-4843-b3ca-15da8207807d" ||
			org.id === "0fc98650-906c-4c6f-a469-9da81626c245" ||
			org.id === "32e834dd-8a8b-49ed-83d6-b31801285b5c" ||
			org.id === "74b3106d-307f-40fa-835a-66cb78eb8262" ||
			org.id === "a243817e-beed-4519-b390-9a21690e67c1"
		) {
			return "Aggregator";
		} else if (org.id === "866d5bc5-5d63-4022-ad3a-a0dcfdcf1b03") {
			return "merchant";
		} else if (org.id === "26b777eb-28b2-4243-bd97-d6766369b818") {
			return "Buyer - Entreprise Brands";
		} else {
			return "buyer";
		}
	};

	return {
		viewOrganization,
		listOrganizationSubsidiaries,
		addSeedData,
		setOrgType,
	};
};
