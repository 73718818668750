import { useCookie } from "nuxt/app";
import type { User } from "@/types/user";
import { randomString } from "@/utils/string";

const COOKIE_OPTIONS = {
	maxAge: 86400 * 30,
	sameSite: true,
	secure: true,
};

export interface IdentityParams {
	state: string;
	token: string;
	redirect_url?: string;
	result: string;
}

export const useAuth = () => {
	const { platformName, baseUrl, authUrl } = useRuntimeConfig().public;
	const { loginAuth } = useURMApi();
	const userStore = useUserStore();

	const getSignInURL = (redirect_url?: string): string => {
		const csrf_token = randomString();
		const stateTokenCookie = useCookie<string | null>("state_token", {
			...COOKIE_OPTIONS,
			sameSite: "strict",
		});
		stateTokenCookie.value = csrf_token;

		const signin_params: any = {
			state: csrf_token,
			platform: platformName,
			return_url: baseUrl + "/auth",
		};
		if (redirect_url) {
			signin_params.redirect_url = redirect_url;
		}

		const query_string = new URLSearchParams(signin_params).toString();

		return authUrl + "/sign-in?" + query_string;
	};

	const getRedirectURI = (query_params: any): string => {
		if (query_params.redirect_url !== undefined) {
			return query_params.redirect_url.toString();
		}

		return "/";
	};

	const tengivaSSOSignIn = async (params: IdentityParams): Promise<User> => {
		if (!params.token) {
			throw new Error("Missing Token");
		}

		// Confirm the state token wasn't touched
		const stateTokenCookie = useCookie<string | null>("state_token", {
			...COOKIE_OPTIONS,
			sameSite: "strict",
		});
		if (params.state !== stateTokenCookie.value) {
			throw new Error("Unrecognized state");
		}
		stateTokenCookie.value = null;

		// Init the jwt cookie here - doing it after the loginAuth() call causes an erroer
		const jwtTokenCookie = useCookie<string | null>("jwt_token", {
			...COOKIE_OPTIONS,
			sameSite: "strict",
		});

		const { response, error } = await loginAuth({
			sso_partner: "Tengiva",
			sso_credential: params.token,
		});
		if (error) {
			throw error;
		}

		// Update the jwt token cookie
		jwtTokenCookie.value = (<any>response!.data).token;

		// Get the User from the URM
		const user = (<any>response!.data).user;
		if (!user) {
			throw new Error("Missing User");
		}

		userStore.setUser(user);
		return user;
	};

	return {
		getRedirectURI,
		getSignInURL,
		tengivaSSOSignIn,
	};
};
